import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 120px;
    text-align: justify;
    @media (max-width: 600px) {
        margin-left: 15px;
        margin-right: 15px;
      }
`;

const Title = styled.h1`
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: left;
`;

const Description = styled.p`
    color: #555;
    font-size: 1.1rem;
    text-align: justify;
    line-height: 1.6;
    margin-bottom: 20px;
`;

const Image = styled.img`
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
`;

const Evenement = () => {
    const { id } = useParams();

    // Dummy data for activity details (replace with actual data fetching)
    const evenement = {
        id: id,
        title: "Celebrate the New Year with us!",
        description: "Join us for a festive celebration of Rosh Hashanah, the hebraic New Year! We will be hosting a special event with apples and honey, cotton candies and traditional songs. We can't wait to welcome you and celebrate together!",
        ImageURL: process.env.PUBLIC_URL +"/roshHashana.jpeg",
    };

    return (
        <Container>
            <Title>{evenement.title}</Title>
            <Description>{evenement.description}</Description>
            <Image src={evenement.ImageURL} alt={evenement.title}/>
        </Container>
    );
};

export default Evenement;