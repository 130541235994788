import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 120px;
  background: white;
  padding: 20px;
`;

const Title = styled.h1`
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: left;
`;

const Description = styled.div`
  color: #555;
  font-size: 1.1rem;
  line-height: 1.8;
  white-space: pre-wrap;
  text-align: justify;
`;

const Image = styled.img`
  max-width: 80%; /* Adjust the percentage to control the image size */
  max-height: 500px; /* Adjust the height to control the image size */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
  object-fit: contain; /* Ensure the image is not cropped */
  display: block;
  margin: auto;
`;

const Evenement = () => {
  const evenement = {
    title: 'The Life Sciences Start-up Ecosystem in Israel',
    description: `
We were thrilled to host Dr. Laurent Choppe, Managing Partner at Cukierman & Co. Life Sciences, who delivered an inspiring presentation on Israel's life sciences startup ecosystem.

Key takeaways:

🔬 Unmatched R&D Powerhouse:
Israel invests 6% of GDP in R&D, a global benchmark for prioritizing progress. With 91 unicorns, the ecosystem has matured from the "Start-Up Nation" into a vibrant "Scale-Up Nation."

🧬 Life Sciences on the Rise:
Over 1,800 active companies and 78,000 professionals are advancing fields like digital health, AI-driven drug discovery, and neurotechnology, driving meaningful progress in healthcare.

🏛️ Infrastructure That Inspires:
Institutions like the Technion - Israel Institute of Technology and Weizmann Institute of Science provide the backbone for technology transfer and spin-offs, supported by forward-thinking government initiatives like those from the Israel Innovation Authority.

🤯 Cultural Superpower:
A spirit of chutzpah (audacity), resilience, and a problem-solving mindset fuels Israel's unstoppable entrepreneurial drive.

🌍 Global Impact:
With over $80B raised internationally, Israeli startups are deeply integrated into global markets, forming meaningful partnerships in the U.S., Europe, and Asia.

🚀 What This Means for the Future:
The session reminded us that great innovation isn’t just about technology—it’s about people, collaboration, and the willingness to push boundaries.
We’re grateful for the opportunity to learn from Dr. Choppe and the remarkable ecosystem he represents. 
The future of life sciences is bold, collaborative, and happening right now in Israel!
    `,
    images: [
      process.env.PUBLIC_URL + '/conf1.jpeg',
      process.env.PUBLIC_URL + '/conf2.jpg',
      process.env.PUBLIC_URL + '/conf3.jpg',
      process.env.PUBLIC_URL + '/conf4.jpg',
    ],
  };

  return (
    <Container>
      <Title>{evenement.title}</Title>
      <Description>{evenement.description}</Description>
      <Carousel
        showArrows={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000}
        stopOnHover={true}
        swipeable={true}
        emulateTouch={true}
        dynamicHeight={true}
      >
        {evenement.images.map((src, index) => (
          <div key={index}>
            <Image
              src={src}
              alt={`Event Image ${index + 1}`}
              onError={(e) => (e.target.src = process.env.PUBLIC_URL + '/placeholder.jpg')}
            />
          </div>
        ))}
      </Carousel>
    </Container>
  );
};

export default Evenement;